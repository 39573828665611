import ContactForm from "../components/ContactForm";

function ContactPage() {
  return (
    <div>
      <ContactForm/>
    </div>
  );
}

export default ContactPage;
